import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';
import Button from '../../components/button';

const claimStyle = {
  marginBottom: '48px',
};

const donateBoxStyle = {
  backgroundColor: '#F5F7FA',
  borderRadius: '4px',
  fontSize: '20px',
  padding: '48px',
  textAlign: 'left',
};

const wireTransferDetail = {
  fontSize: '14px',
  marginBottom: '0',
  marginTop: '12px',
};

const wireTransferHeaderLabel = {
  fontSize: '12px',
  fontWeight: 'bold',
};

const PageIntroSection = () => (
  <Section title="Reservieren">
    <Container>
      <Row>
        <Col md={6}>
          <p className="content" style={claimStyle}>
            Wir freuen uns über Ihr Interesse an unserer Ferienwohnung.
          </p>
          <p className="content-small">
            Für Reservierungsanfragen senden Sie bitte eine E-Mail an: <a href="mailto:h.m.eichinger@t-online.de" target="_blank" rel="noopener noreferrer">h.m.eichinger@t-online.de</a><br />
            <div style={{ marginTop: '24px', textAlign: 'center' }}>
              <a href="mailto:h.m.eichinger@t-online.de" target="_blank" rel="noopener noreferrer"><Button primary>Kontakt</Button></a>
            </div>
            <br /><br />
          </p>
        </Col>
        <Col md={6}>
          <p className="content" style={donateBoxStyle}>
            <p>
              <strong>Kontakt:</strong><br />
              <p style={wireTransferDetail}><span style={wireTransferHeaderLabel}>Telefon</span><br />0171 - 978 21 76</p>
              <p style={wireTransferDetail}><span style={wireTransferHeaderLabel}>E-Mail</span><br />h.m.eichinger@t-online.de</p>
            </p>
          </p>
        </Col>
      </Row>
    </Container>
  </Section>
)

export default PageIntroSection
