import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Button from '../components/button';

import IntroSection from '../sections/reservieren/01_intro';
import PageIntroSection from '../sections/reservieren/02_pageIntro';
import BuchungsansichtSection from '../sections/reservieren/03_BuchungsansichtSection';
import PreiseSection from '../sections/reservieren/04_PreiseSection';

const highlightContainer = {
  backgroundColor: '#62b7f0',
  boxShadow: '0px 16px 40px rgba(31, 41, 51, 0.16)',
  borderRadius: '4px',
  color: '#FFF',
  padding: '48px',
  textAlign: 'center',
  maxWidth: '920px',
  margin: '0 auto',
  marginBottom: '250px',
};

const ReservierenPage = () => (
  <Layout fluidContainer backToHome>
    <SEO
      image="/images/og-image-tech.jpg"
    />

    <IntroSection />
    <BuchungsansichtSection />
    <PreiseSection />
    <PageIntroSection />

    {false && (<div style={highlightContainer}>
      <p className="content">Haben Sie noch Fragen?</p>
      <Link to="/imprint"><Button>Kontakt</Button></Link>
    </div>)}
  </Layout>
)

export default ReservierenPage
