import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const avoidInteractionContainer = {
  maxWidth: '920px',
  top: '120px',
  bottom: 0,
  left: 0,
  right: 0,
  position: 'absolute',
};

const BuchungsansichtSection = () => {
  return (
    <Section title="Verfügbarkeit" headerStyle={{ width: '210px' }}>
      <Container>
        <Row>
          <Col xs={12} style={{ textAlign: 'center', position: 'relative' }}>
            <iframe
              id="iframe"
              height="600px"
              width="960px"
              src={process.env.UPSTALSBOOM_WEBSITE_DATA_URL}
              style={{ border: 0, overflow: 'hidden' }}
              scrolling="no"
            />
            <div style={avoidInteractionContainer}></div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default BuchungsansichtSection
